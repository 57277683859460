<template>
    <Head :title="title"/>
    <Banner/>

    <div class="bg-white">
        <!-- Header -->
        <header class=" inset-x-0 top-0 z-50" :class="{
            'bg-gray-900 relative': !route().current('index'),
            'absolute': route().current('index'),
        }">
            <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div class="flex lg:flex-1">
                    <Link :href="route('index')"
                          class="-m-1.5 p-1.5"
                    >
                        <ApplicationLogo class="h-18"/>
                    </Link>
                </div>
                <div class="flex lg:hidden">
                    <button type="button"
                            :class="{
                                'text-white': route().current('index'),
                            }"
                            class="-m-2.5 -mt-3 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            @click="mobileMenuOpen = true">
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon class="size-8" aria-hidden="true"/>
                    </button>
                </div>
                <div class="hidden lg:flex lg:gap-x-12 mt-2">
                    <a v-for="item in navigation"
                       :key="item.name"
                       @click="navigateToItem(item)"
                       :class="{ 'border-b-2 font-bold': item.current, 'text-white': !item.current}"
                       class="leading-6 text-white pb-2 border-b-2 border-transparent hover:border-white cursor-pointer">{{ item.name }}</a>
                </div>
                <div class="hidden lg:flex lg:flex-1 lg:justify-end gap-4">
                    <template v-if="!isLoggedIn">
                        <Link
                            :href="route('login')"
                            class="rounded-md px-3 py-2 ring-white text-white ring-1 ring-transparent transition hover:ring-secondary hover:text-secondary focus:outline-none focus-visible:ring-[#FF2D20] dark:text-white dark:hover:text-white/80 dark:focus-visible:ring-white"
                        >
                            Log in
                        </Link>

                        <Link
                            :href="route('register')"
                            class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-white hover:bg-accent hover:text-black h-10 px-4 py-2 border border-primary hover:border-accent"
                        >
                            Start Free Trial
                        </Link>
                    </template>
                    <template v-else>
                        <p class="my-auto text-white">
                            Welcome Back.
                        </p>
                        <Link
                            :href="route('login')"
                            class="rounded-md px-3 py-2 ring-white text-white ring-1 ring-transparent transition hover:ring-secondary hover:text-secondary focus:outline-none focus-visible:ring-[#FF2D20] dark:text-white dark:hover:text-white/80 dark:focus-visible:ring-white"
                        >
                            Go to my Account
                        </Link>
                    </template>
                </div>
            </nav>
            <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
                <div class="fixed inset-0 z-50"/>
                <DialogPanel
                    class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div class="flex items-center justify-between">
                        <a href="#" class="-m-1.5 p-1.5">
                            <span class="sr-only">Greetmate.ai</span>
                            <ApplicationLogo class="block h-9 w-auto invert"/>
                        </a>
                        <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700"
                                @click="mobileMenuOpen = false">
                            <span class="sr-only">Close menu</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <a v-for="item in navigation" :key="item.name" @click="navigateToItem(item)"
                                   class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{
                                        item.name
                                    }}</a>
                            </div>
                            <div class="py-6">
                                <a href="/login"
                                   class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log
                                    in</a>

                                <a href="/register"
                                   class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Register</a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>

        <slot/>

        <!-- Footer -->
        <footer class="mt-32 bg-gray-900 sm:mt-56" aria-labelledby="footer-heading">
            <h2 id="footer-heading" class="sr-only">Footer</h2>
            <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
                <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div>
                        <ApplicationLogo class="w-64"/>
                    </div>
                    <div class="mt-6 lg:mt-16 grid md:grid-cols-3 gap-8 xl:mt-0 col-span-2">
                        <div class="">
                            <div class="mt-10 md:mt-0">
                                <h3 class="text-sm font-semibold leading-6 text-white">Info</h3>
                                <ul role="list" class="mt-6 space-y-4">
                                    <li v-for="item in footerNavigation.support" :key="item.name">
                                        <a @click="navigateToItem(item)"
                                           class="cursor-pointer text-sm leading-6 text-gray-300 hover:text-white">{{
                                                item.name
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="">
                            <div>
                                <h3 class="text-sm font-semibold leading-6 text-white">App</h3>
                                <ul role="list" class="mt-6 space-y-4">
                                    <li v-for="item in footerNavigation.company" :key="item.name">
                                        <a @click="navigateToItem(item)"
                                           class="cursor-pointer text-sm leading-6 text-gray-300 hover:text-white">{{
                                                item.name
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="mt-10 md:mt-0">
                            <h3 class="text-sm font-semibold leading-6 text-white">More</h3>
                            <ul role="list" class="mt-6 space-y-4">
                                <li v-for="item in footerNavigation.legal" :key="item.name">
                                    <a @click="navigateToItem(item)"
                                       class="cursor-pointer text-sm leading-6 text-gray-300 hover:text-white">{{
                                            item.name
                                        }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
                    <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" @click="navigateToItem(item)"
                           class="cursor-pointer text-gray-500 hover:text-gray-400">
                            <span class="sr-only">{{ item.name }}</span>
                            <component :is="item.icon" class="h-6 w-6" aria-hidden="true"/>
                        </a>
                    </div>
                    <p class="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">&copy;
                        {{ new Date().getFullYear() }} Pacific Codeline LLC. All rights reserved.</p>
                </div>
            </div>
        </footer>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import {Dialog, DialogPanel} from '@headlessui/vue'
import {Bars3Icon, XMarkIcon} from '@heroicons/vue/24/outline'
import {Link, router, usePage} from "@inertiajs/vue3";

import {Head} from "@inertiajs/vue3";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import Banner from "@/Components/Banner.vue";

const isLoggedIn = usePage().props.isLoggedIn;

const navigation = [
    {name: 'Home', href: route('index'), current: route().current('index')},
    {name: 'Features', href: '#', scrollTo: 'features'},
    {name: 'Pricing', href: '#', scrollTo: 'pricing'},
    {name: 'Mission', href: route('mission'), current: route().current('mission')},
    {name: 'Blog', href: route('blog'), current: route().current('blog')},
]

const navigateToItem = async (item) => {
    mobileMenuOpen.value = false;
    if (item.scrollTo) {
        if (route().current('index')) {
            document.getElementById(item.scrollTo).scrollIntoView({behavior: 'smooth'})
        } else {
            await router.visit(route('index'), {method: 'get', replace: true})

            setTimeout(() => {
                document.getElementById(item.scrollTo).scrollIntoView({behavior: 'smooth'})
            }, 150)

        }
    } else {
        router.visit(item.href, {method: 'get'});
    }
}

const footerNavigation = {
    support: [
        {name: 'Pricing', href: '#', scrollTo: 'pricing'},
    ],
    company: [
        {name: 'Register ', href: route('register'), current: route().current('register')},
        {name: 'Login', href: route('login'), current: route().current('login')},
    ],
    legal: [
        {name: 'Blog', href: route('blog'), current: route().current('blog')},
        {name: 'Contact', href: route('policy.show'), current: route().current('policy.show')},
        {name: 'Privacy', href: route('policy.show')},
        {name: 'Terms', href: route('terms.show')},
    ],
}

const mobileMenuOpen = ref(false)


defineProps({
    title: String,
});
</script>
